import Vue from "vue";
import Vuex from "vuex";
import { Source } from "../types/Source";
import { fetchCountries, fetchSources } from "../api/sources.api";
import { SearchRequest } from "@/types/SearchRequest";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    company: "",
    sources: Array<Source>(),
    countries: [{ countryId: 0, country: "Todos" }],
    states: [{ stateId: 0, state: "Todos" }],
    pagination: { totalItems: 200, rowsPerPage: 100, page: 1 },
    token: ""
  },

  mutations: {
    CHANGE(state, company) {
      state.company = company;
    },

    SET_SOURCES(state, sources) {
      state.sources = sources;
    },

    ADD_COUNTRIES(state, countries) {
      state.countries.push(...countries);
    },

    ADD_STATES(state, states) {
      state.states.push(
        ...states.filter((item: any) => item.stateId !== undefined)
      );
    },

    SET_PAGE(state, page) {
      state.pagination.page = page;
    },

    SET_TOTAL_ITEMS(state, totalItems) {
      state.pagination.totalItems = totalItems;
    },

    SET_TOKEN(state, token) {
      state.token = token;
    }
  },

  actions: {
    async fetchSources({ commit }, payload: SearchRequest) {
      try {
        const response = await fetchSources(payload);
        commit("SET_SOURCES", response.data.sources);
        commit("SET_TOTAL_ITEMS", response.data.numDocs);
      } catch (error) {
        console.log(error);
      }
    },

    async getCountries({ commit }) {
      try {
        const response = await fetchCountries();
        commit("ADD_COUNTRIES", response.data);
        commit("ADD_STATES", response.data);
      } catch (error) {
        console.log(error);
      }
    },

    setPage({ commit }, page) {
      commit("SET_PAGE", page);
    },

    setTotalItems({ commit }, totalItems) {
      commit("SET_TOTAL_ITEMS", totalItems);
    },

    setToken({ commit }, token) {
      commit("SET_TOKEN", token);
    }
  },

  getters: {
    company: state => state.company,
    token: state => state.token
  }
});
