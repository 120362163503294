import httpClient from "./httpClient";
import { SearchRequest } from "@/types/SearchRequest";

const fetchSources = (request: SearchRequest) => {
  const params = {
    c: request.collection,
    q: request.query,
    ids: request.ids,
    offset: request.offset,
    limit: request.limit,
    ct: request.country,
    st: request.state
  };
  return httpClient.get("/api/sources", {
    params
  });
};

const fetchCountries = () => httpClient.get("/api/countries");

export { fetchSources, fetchCountries };
