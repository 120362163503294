
import Vue from "vue";
import SourcesTable from "@/components/SourcesTable.vue";
import { COMPANIES } from "@/constants/companies";

export default Vue.extend({
  name: "SourcesView",
  components: {
    SourcesTable
  },
  computed: {
    footer() {
      if (this.$store.getters.company === COMPANIES.CSERVICE) {
        return COMPANIES.CSERVICE_RIGHTS;
      } else {
        return COMPANIES.KNEWIN_RIGHTS;
      }
    }
  },
  created() {
    const params = new URLSearchParams(window.location.search);
    const token = params.get("token");

    if (token) {
      this.$store.dispatch("setToken", token);
    }
  }
});
