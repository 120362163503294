import axios, {
  AxiosRequestConfig,
  AxiosResponse,
  AxiosError
} from "axios";
import store from "./../store/index";

const httpClient = axios.create({
  timeout: 30000,
  headers: {
    "Content-Type": "application/json"
  }
});

httpClient.interceptors.request.use(
  (config: AxiosRequestConfig) => {
    if (config.method === "get") {
      if (!config.headers) {
        config.headers = {};
      }

      const token = store.getters.token;

      if (token) {
        config.headers["x-api-key"] = token;
      }
    }
    return config;
  },
  (error: AxiosError) => Promise.reject(error)
);

const errorInterceptor = (error: AxiosError) => {
  if (error.response) {
    switch (error.response.status) {
      case 400:
        console.error(error.response.status, error.message);
        break;

      default:
        console.error(error.response.status, error.message);
    }
  }
  return Promise.reject(error);
};

const responseInterceptor = (response: AxiosResponse) => {
  switch (response.status) {
    case 200:
      break;
    default:
  }
  return response;
};

httpClient.interceptors.response.use(responseInterceptor, errorInterceptor);

export default httpClient;
