import Vue from "vue";
import Router, { Route } from "vue-router";
import store from "@/store";
import SourcesTable from "@/components/SourcesTable.vue";


Vue.use(Router);

const router = new Router({
  mode: 'history',
  routes: [
    {
      path: "/",
      component: SourcesTable
    }
  ]
})

router.beforeEach((to: any, from: any, next: any) => {
  store.commit('CHANGE', to.query.c);
});

export default router;
